@import '@vg-constellation/angular-15/styles/c11n';
@import '@vg-constellation/angular-15/styles/icons';
@import '@vg-constellation/angular-15/styles/components/accordion';
@import '@vg-constellation/angular-15/styles/components/link';
@import '@vg-constellation/angular-15/styles/components/table';
@import '@vg-constellation/angular-15/styles/variables';
@import '@vg-constellation/angular-15/styles/breakpoints';
@import '@vg-constellation/angular-15/styles/components/hint-error';
@import '@vg-constellation/angular-15/styles/components/radio';
@import '@vg-constellation/angular-15/styles/icons';
@import '@vg-constellation/angular-15/styles/components/nav-overflow';
@import '@vg-constellation/angular-15/styles/components/banner';

.storybook-horizontal-radios {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

// .c11n-banner__content-header {
//   font-weight: 400 !important;
// }

.hd-modal-entrypoint > dialog.c11n-modal-dialog {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.spinner-on-screen {
  position: fixed;
  z-index: 1602;
  top: calc(50vh - 2.33rem);
  left: calc(50vw - 1.63rem);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .extra-gap .c11n-card__body {
    margin-bottom: 40px !important;
  }
}

.header-label {
  color: var(--c-11-n-typography-on-light, #040505);
  font-family: Mark Pro;
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

.step-indicator-container {
  margin-top: 64px;
  margin-bottom: 48px;
}

.title-container {
  margin-top: 12px;

  .title-text {
    color: var(--c-11-n-icon-on-light, #040505);
    font-family: Mark Pro;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}


.new-address-container {
  margin-top: 40px;

  .new-address-title {
    color: #040505;
    font-family: Mark Pro;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .new-address {
    color: var(--c-11-n-typography-on-light, #040505);
    font-family: Mark Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }

  span {
    color: var(--c-11-n-typography-on-light, #040505);
    font-family: Mark Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.select-account-container {
  margin-top: 40px;

  .select-acc-label {
    color: #040505;
    font-family: Mark Pro;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .border-separator {
    background: rgba(0, 0, 0, 0.00);
    box-shadow: 0px 2px 0px 0px #CBCECE inset;
    height: 2px;
  }
}

.c11n-accordion__heading {
  span {
    color: #040505;
    font-family: Mark Pro;
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }
}

.symbol-text {
  color: #040505;
  font-family: Mark Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.account-name {
  color: #040505;
  font-family: Mark Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.disableRow {
  color: #bbbbbb !important;
}


@media screen and (min-width : 961px) {
  .extra-space {
    padding: 0 15%;
  }
}

.c11n-table__thead-th--active-sort.ascending .c11n-link--icon:hover svg use:nth-child(1) {
  fill: #145bff !important;
}

.c11n-table__thead-th--active-sort.ascending .c11n-link--icon:hover svg use:nth-child(2) {
  fill: #cbcece !important;
}

.c11n-table__thead-th--active-sort.descending .c11n-link--icon:hover svg use:nth-child(1) {
  fill: #cbcece !important;
}

.c11n-table__thead-th--active-sort.descending .c11n-link--icon:hover svg use:nth-child(2) {
  fill: #145bff !important;
}

.btn-container {
  margin-top: 56px;
  margin-bottom: 90px !important;
}